.App > div {
	display: flex;
	flex-direction: row;
	justify-content: stretch;
	align-items: center;
}

.MultimeterIcon {
	height: 352px;
	width: 185px;
}

.MultimeterIcon .arrow {
	rotate: var(--multimeter-rotation);
	transform-origin: center;
}

.probe-port {
	height: 67px;
}

.probe-pin {
	height: 178px;
}

.distribution-board {
	margin-left: 3em;
	width: 325px;
	flex: 1;
}

.toolbox {
	position: absolute;
	z-index: 2;
	bottom: 1em;
	right: 1em;
	left: 1em;
	height: 5em;
	padding: 1em;
	background: #dddddd;
	border: 1px solid #bbbbbb;
	border-radius: 0.5em;
}

.toolbox > img {
	height: 3em;
	width: 3em;
	float: left;
}

.multimeter {
	position: relative;
}

.multimeter span {
	position: absolute;
	font-family: 'Seven Segment';
	font-size: 44px;
	top: 44px;
	right: 36px;
}
