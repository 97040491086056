body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
	box-sizing: border-box;
}
html,
body,
#root,
.App {
	padding: 0;
	margin: 0;
	width: 800px;
	height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	flex: 1;
	position: relative;
	overflow: hidden;
	user-select: none;
}
/* body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}
input,
button,
textarea,
select {
	font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}
#root,
#__next {
	isolation: isolate;
} */

/* END CSS Reset */
